import { useMediaQuery, useTheme } from "@mui/material";

/** Anything below this will use the mobile layout */
export const mobileBreakpoint = "lg";

export const useIsMobile = (): boolean => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint));
  return isMobile;
};
