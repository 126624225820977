import createCache from "@emotion/cache";
import { EmotionCache } from "@emotion/react";
// TODO re-enable once date-fns-tz supports date-fns v3 :( :( :(
// import { formatInTimeZone } from "date-fns-tz";
import {
  Firestore,
  collection,
  doc,
  getDocs,
  writeBatch,
} from "firebase/firestore";
import sanitizeHtml from "sanitize-html";

export const innerText = (html: string = "") => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  return tempDiv.textContent || tempDiv.innerText || "";
};

export const sanitizeAllHtml = (str: string) =>
  sanitizeHtml(str.replace(/>/g, "> "), { allowedTags: [] }).replace(
    /  +/g,
    " "
  );

// export const formatTimezone = (timezone: string, format = "zzzz") =>
//   formatInTimeZone(new Date(), timezone, format);

// export const formatTimezoneWithDefault = (
//   timezone?: string,
//   format = "zzzz"
// ) => {
//   const tz = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
//   return formatTimezone(tz, format);
// };

export const createEmotionCache = (): EmotionCache =>
  createCache({ key: "css" });

// https://github.com/FirebaseExtended/reactfire/discussions/228#discussioncomment-182830
// Prevent "Missing or insufficient permissions"
export const clearFirestoreCache = () => {
  const map = globalThis["_reactFirePreloadedObservables"];
  Array.from(map.keys()).forEach(
    (key: string) => key.includes("firestore") && map.delete(key)
  );
};

export type GooglePlaceUrlProps = {
  query: string;
  placeId?: string;
};
/**
 * Build a google maps url given a placeId and query, or an address.
 * https://developers.google.com/maps/documentation/urls/get-started#search-action
 */
export const googlePlaceUrl = (params: GooglePlaceUrlProps) => {
  const googleMapsUrl = new URL("https://www.google.com/maps/search/");
  googleMapsUrl.searchParams.append("api", "1");
  googleMapsUrl.searchParams.append("query", params.query);

  if (params.placeId) {
    googleMapsUrl.searchParams.set("query_place_id", params.placeId);
  }

  return googleMapsUrl.toString();
};

// from https://stackoverflow.com/a/60137639/65311
export async function copyCollection(
  firestore: Firestore,
  srcCollectionName: string,
  destCollectionName: string
): Promise<void> {
  const documents = await getDocs(collection(firestore, srcCollectionName));
  let batch = writeBatch(firestore);
  const destCollection = collection(firestore, destCollectionName);
  let i = 0;
  for (const sourceDoc of documents.docs) {
    const docRef = doc(destCollection, sourceDoc.id);
    batch.set(docRef, sourceDoc.data());
    i++;
    if (i > 400) {
      // write batch only allows maximum 500 writes per batch
      i = 0;
      console.log("Intermediate committing of batch operation");
      await batch.commit();
      // reset batch for next batch
      batch = writeBatch(firestore);
    }
  }
  if (i > 0) {
    console.log(
      "Firebase batch operation completed. Doing final committing of batch operation."
    );
    await batch.commit();
  } else {
    console.log("Firebase batch operation completed.");
  }
}

export const isAndroid = () => {
  if (navigator.userAgentData) {
    return navigator.userAgentData.platform.toLowerCase() === "android";
  }
  return /Android/i.test(navigator.userAgent);
};
