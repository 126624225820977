import { updateDoc } from "firebase/firestore";
import { memo, useEffect } from "react";
import useNetworkFromUrl from "~/hooks/useNetworkFromUrl";
import { useNetworkMembership } from "~/hooks/useNetworkMembership";
import { useLoggedInState } from "~/lib/useLoggedInState";

/**
 * Keep track of the current network
 *
 * 1. The URL is the first source of truth for current network.
 * 2. If the user is not at a network route (e.g. onboarding), then fall back to
 * the `currentNetworkMembership` network.
 */
export const NetworkState = memo(function NetworkStateMemo({
  setPrimaryColor,
}: {
  setPrimaryColor: (primaryColor: string | undefined) => void;
}): React.ReactElement {
  const { profile, setNetwork } = useLoggedInState();
  const networkFromUrl = useNetworkFromUrl();
  const { networkMembership } = useNetworkMembership({
    network: networkFromUrl,
    profile,
  });

  console.log("NetworkState", { profile, networkFromUrl, networkMembership });

  useEffect(
    function handleNetworkUpdate() {
      if (networkFromUrl) {
        console.log("NetworkState update global network", {
          networkFromUrl,
        });
        setNetwork(networkFromUrl);

        // set the primary color if white labeling is enabled
        const isWhiteLabelingEnabled =
          networkFromUrl?.settings?.isWhiteLabelingEnabled;
        const primaryColor =
          networkFromUrl?.settings?.whiteLabelInfo?.primaryColor;
        if (isWhiteLabelingEnabled && primaryColor) {
          console.log("updating primary color for white labeling", {
            primaryColor,
            isWhiteLabelingEnabled,
            networkFromUrl,
          });
          setPrimaryColor(primaryColor);
        } else {
          setPrimaryColor(undefined);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [networkFromUrl?.slug]
  );

  useEffect(
    function redirectIfMembershipRemoved() {
      // If the user has no network membership inthe network they are trying to access
      if (networkFromUrl && profile && !networkMembership) {
        console.log("Not a Member");
        const updateCurrentNetwork = async () => {
          await updateDoc(profile.ref, {
            currentNetworkMembership: null,
          });
        };

        // If current membership of user profile is set as this network, remove it.
        if (
          profile.currentNetworkMembership?.network.id === networkFromUrl.id
        ) {
          updateCurrentNetwork();
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [networkMembership]
  );
  return <></>;
});
