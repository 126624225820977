import { create } from "zustand";

export type SidebarNavState = {
  expanded: boolean;
  toggle: () => void;
  close: () => void;
  open: () => void;
};

export const useSidebarNavState = create<SidebarNavState>((set) => ({
  // sidebar is open by default
  expanded: true,
  toggle: () => set((state) => ({ expanded: !state.expanded })),
  close: () => set({ expanded: false }),
  open: () => set({ expanded: true }),
}));
