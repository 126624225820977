import {
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocData,
} from "reactfire";
import {
  query,
  collection,
  where,
  or,
  and,
  orderBy,
  doc,
} from "@firebase/firestore";
import { useLoggedInState } from "~/lib/useLoggedInState";
import { Group, LiteGroup } from "@converge-collective/common/models/Group";
import { converters } from "~/lib/converter";
import { DocStatuses } from "@converge-collective/common/models/DocMeta";
import { WithRef } from "@converge-collective/common/models/Base";
import { useNetworkMembership } from "./useNetworkMembership";
import { sortBy } from "lodash";

export function useGroupMembership(group: WithRef<Group>) {
  const { profile } = useLoggedInState();

  const membershipQuery = doc(
    group.ref,
    "groupMembers",
    profile?.id || ""
  ).withConverter(converters.groupMember.read);

  return useFirestoreDocData(membershipQuery);
}

export function useMyGroups(): {
  groups: WithRef<Group>[];
  unreadMessages: Record<string, number> | undefined;
  status: "loading" | "error" | "success";
  networkMembership: LiteGroup[];
} {
  const firestore = useFirestore();

  // My Config
  const { profile, network } = useLoggedInState();
  const { networkMembership } = useNetworkMembership({ profile, network });

  // Firebase query
  const myGroupsQuery = query(
    (network && profile
      ? query(
          collection(network.ref, "groups"),
          and(
            where("memberIds", "array-contains", profile.id),
            or(
              where("latestDocUpdate.status", "==", DocStatuses.Published),
              where("latestDocUpdate.status", "==", DocStatuses.Active)
            )
          ),
          orderBy("name")
        )
      : collection(firestore, "noop")
    ).withConverter(converters.group.read)
  );
  // Firestore data
  const { data: groups = [], status } =
    useFirestoreCollectionData(myGroupsQuery);

  // Unread Messages
  const unreadMessages = networkMembership?.groupUnreadCount;

  return {
    groups,
    unreadMessages,
    status,
    networkMembership: networkMembership
      ? sortBy(Object.values(networkMembership.groupMemberships || {}), "name")
      : [],
  };
}
