import { isBrowser } from "browser-or-node";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import React from "react";
import {
  AnalyticsProvider,
  AuthProvider,
  FirestoreProvider,
  StorageProvider,
  useFirebaseApp,
  useInitPerformance,
} from "reactfire";
import Wrap from "~/components/Wrap";

const InitClientSide = () => {
  useInitPerformance(async (app) => {
    const { getPerformance } = await import("firebase/performance");
    return getPerformance(app);
  });
  return <></>;
};

export const ReactFireWrapper = ({
  children,
}: {
  children: JSX.Element;
}): React.ReactElement => {
  const app = useFirebaseApp();
  const auth = getAuth(app);
  const firestore = getFirestore(app);
  const storage = getStorage(app);
  // must be initialized client side only
  // initializeEmulator(app);
  // seedFireStore(firestore, auth);

  return (
    <AuthProvider sdk={auth}>
      <StorageProvider sdk={storage}>
        {isBrowser && <InitClientSide />}
        <Wrap
          condition={isBrowser}
          wrapper={(innerChildren) => (
            <AnalyticsProvider sdk={getAnalytics(app)}>
              {innerChildren}
            </AnalyticsProvider>
          )}
        >
          <FirestoreProvider sdk={firestore}>{children}</FirestoreProvider>
        </Wrap>
      </StorageProvider>
    </AuthProvider>
  );
};
