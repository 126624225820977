import React from "react";

type WrapProps = {
  children: React.ReactElement;
  condition: boolean;
  wrapper: (children: React.ReactElement) => JSX.Element;
};

const Wrap: React.FC<WrapProps> = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

export default Wrap;
