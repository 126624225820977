import { converters } from "~/lib/converter";
import { WithRef } from "@converge-collective/common/models/Base";
import { Network } from "@converge-collective/common/models/Network";
import {
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "@firebase/firestore";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useFirestore, useFirestoreDocData, useUser } from "reactfire";
import useProfile from "./useProfile";

/**
 * Attempt to fetch the network by current url. If the current route isn't a
 * network based page, e.g. onboarding or privacy policy, then fall back to the
 * user's current network membership.
 *
 */
export default function useNetworkFromUrl(): WithRef<Network> | undefined {
  /**
   * Resolved network gets fetched once then used to setup a listener to get
   * live updates
   */
  const [fetchedNetwork, setFetchedNetwork] = useState<WithRef<Network>>();
  const router = useRouter();
  const firestore = useFirestore();
  const { data: user } = useUser();
  const [profile] = useProfile(user?.uid);
  const networkToFetch = router?.query?.network;

  const { data: network } = useFirestoreDocData(
    doc(
      firestore,
      fetchedNetwork ? fetchedNetwork.ref.path : "noop/noop"
    ).withConverter(converters.network.read)
  );

  useEffect(() => {
    console.log("useNetworkFromUrl useEffect", {
      networkToFetch,
      fetchedNetwork,
    });

    const fetchNetworkByPath = async (
      networkPath: string
    ): Promise<WithRef<Network> | undefined> => {
      const networkRef = doc(firestore, networkPath).withConverter(
        converters.network.read
      );
      const networkDoc = await getDoc(networkRef);
      if (!networkDoc.exists()) {
        return undefined;
      }
      return networkDoc.data();
    };
    const fetchNetwork = async () => {
      if (!networkToFetch) {
        if (profile?.currentNetworkMembership?.network.ref.path) {
          // fall back to currentNetworkMembership network
          const networkPath = profile.currentNetworkMembership.network.ref.path;
          const network = await fetchNetworkByPath(networkPath);
          if (network) {
            setFetchedNetwork(network);
          }
        }
        return;
      }

      console.log("useNetwork fetching network", { networkToFetch });
      // we can't perform a collection group query to look up the network by
      // slug because users can only read networks that they belong to

      // we use a different query to lookup the network for logged in users than
      // public users since logged in users have access to private networks that
      // they are members of
      if (user && profile) {
        // regular users can't query across all networks, so we need a network
        // path to fetch first. however, superadmins aren't members of networks,
        // so if the members collection is empty we should fallback to
        // inspecting currentNetworkMembership
        const membersQuery = query(
          collectionGroup(firestore, "members").withConverter(
            converters.networkMembership.read
          ),
          // filtering to networks that the current user is a member of
          where("profileRef", "==", profile.ref),
          where("network.slug", "==", networkToFetch)
        );

        console.log(`useNetwork fetch query for user ${user.uid}`, {
          membersQuery,
        });
        const results = await getDocs(membersQuery);

        const networkPath = !results.empty
          ? results.docs[0].ref.parent.parent?.path
          : profile.currentNetworkMembership?.network.ref.path;

        if (networkPath) {
          const network = await fetchNetworkByPath(networkPath);
          if (network) {
            setFetchedNetwork(network);
          }
        }
      } else {
        // non-logged-in users can only view public networks
        const networksQuery = query(
          collectionGroup(firestore, "networks").withConverter(
            converters.network.read
          ),
          where("public", "==", true),
          where("slug", "==", networkToFetch)
        );
        console.log("useNetwork fetch query for public user", {
          networksQuery,
        });
        const results = await getDocs(networksQuery);
        if (!results.empty) {
          const [firstDoc] = results.docs;
          const n = firstDoc.data();
          setFetchedNetwork(n);
        }
      }
    };
    fetchNetwork();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    profile?.id,
    user?.uid,
    networkToFetch,
    profile?.currentNetworkMembership?.network.ref.path,
  ]);

  console.count("useNetworkFromUrl");
  return network;
}
