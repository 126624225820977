import {
  ThemeOptions,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import type {} from "@mui/x-data-grid/themeAugmentation";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import { merge } from "lodash";
import { Oswald, Yellowtail } from "next/font/google";

const baseHeaderRem = 4; // this is used for h1
const headerDecay = 0.75;
const hLevelToRem = (h: number) =>
  `${baseHeaderRem * Math.pow(headerDecay, h - 1)}rem`;

declare module "@mui/material/styles" {
  interface Palette {
    light: Palette["primary"];
  }
  interface PaletteOptions {
    light?: PaletteOptions["primary"];
  }
}
declare module "@mui/material/Slider" {
  interface SliderPropsColorOverrides {
    light: true;
  }
}
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    light: true;
  }
}
declare module "@mui/material/SvgIcon" {
  interface SvgIconPropsColorOverrides {
    light: true;
  }
}

export const oswald = Oswald({
  weight: ["300", "500"],
  style: ["normal"],
  subsets: ["latin"],
  display: "swap",
});

export const yellowtail = Yellowtail({
  weight: ["400"],
  style: ["normal"],
  subsets: ["latin"],
  display: "swap",
});

/**
 * Store the defaults as a separate var so when we override we can merge in
 * any custom colors. This is necessary to retain custom colors like `light`.
 */
export const basePalette = () => ({
  primary: {
    // main: '#4dd0e1', // cyan
    // main: '#ffffff',
    // main: '#FFC108', // yellow
    // main: '#ef4b4b', // salmon
    // main: '#7ecfc0', // earthy cyan
    main: "#38B4FA", // bright blue from Wix
    contrastText: "#ffffff",
    // main: '#fcf9ea',
  },
  secondary: {
    // main: "#f2e3c9", // earthy brown
    main: "#FFC108", // yellow
  },
  success: {
    main: "#5edb60", // "#4caf50",
  },
  error: {
    main: "#ef4b4b",
  },
  background: {
    default: "#F5F5F5", // whitesmoke
  },
  light: {
    main: "#ffffff",
    contrastText: "#000000",
  },
});

// Create a theme instance.
const theme = createTheme({
  // copy basePalette to avoid mutating it
  palette: basePalette(),
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),

    htmlFontSize: 16,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontWeight: 300,
      fontSize: hLevelToRem(1),
      lineHeight: 1.167,
      letterSpacing: "-0.01562em",
    },
    h2: {
      fontWeight: 300,
      fontSize: hLevelToRem(2),
      lineHeight: 1.2,
      letterSpacing: "-0.00833em",
    },
    h3: {
      fontWeight: 400,
      fontSize: hLevelToRem(3),
      lineHeight: 1.167,
      letterSpacing: "0em",
    },
    h4: {
      fontWeight: 400,
      fontSize: hLevelToRem(4),
      lineHeight: 1.235,
      letterSpacing: "0.00735em",
    },
    h5: {
      fontWeight: 400,
      fontSize: hLevelToRem(5),
      lineHeight: 1.334,
      letterSpacing: "0em",
    },
    h6: {
      fontWeight: 500,
      fontSize: hLevelToRem(6),
      lineHeight: 1.6,
      letterSpacing: "0.0075em",
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: 1.75,
      letterSpacing: "0.00938em",
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: 1.57,
      letterSpacing: "0.00714em",
    },
    body1: {
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: 1.5,
      letterSpacing: "0.00938em",
    },
    body2: {
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: 1.43,
      letterSpacing: "0.01071em",
    },
    button: {
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: 1.75,
      letterSpacing: "0.02857em",
      textTransform: "uppercase",
    },
    caption: {
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 1.66,
      letterSpacing: "0.03333em",
    },
    overline: {
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 2.66,
      letterSpacing: "0.08333em",
      textTransform: "uppercase",
    },
  },
});

// Layer in our overrides
const overridesTheme = createTheme({
  ...theme,

  //
  // component overrides
  //

  components: {
    MuiDialog: {},

    MuiDialogContent: {
      styleOverrides: {
        root: {
          // fix for https://github.com/mui/material-ui/issues/29892
          // requires important or else it'll be overridden to 0
          paddingTop: "12px !important",
        },
      },
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: "1.3rem",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          backgroundColor: "#fbfbfb",
          padding: "18px 24px",
          borderTop: "1px solid #eaeaea",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          height: "100%",
          width: "100%",
          // cursor: 'pointer',
          boxShadow: "0 0px 20px 0 rgba(0,0,0,0.12)",
          transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
          border: 1,
          borderStyle: "solid",
          borderColor: "transparent",
          borderRadius: 8,
          // hover pop by default
          // "&:hover": {
          //   transform: "scale(1.01)",
          //   // boxShadow: `0 4px 20px 0 ${fade(theme.palette.primary.main, 0.12)}`,
          //   boxShadow: `0 4px 20px 0 ${fade(theme.palette.primary.main, 0.52)}`,
          // },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          backdropFilter: "blur(1px)",
        },
      },
    },
    // https://mui.com/material-ui/customization/theme-components/#default-props
    MuiButton: {
      // disable elevation on buttons
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          // if we wanted to remove all caps on buttons we could do so like
          // this:
          // textTransform: "none",
        },
      },
    },

    MuiAlert: {
      styleOverrides: {
        message: {
          width: "100%",
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontFamily: "inherit",
          fontWeight: "500",
          fontSize: "0.92rem",
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {},
        elevation1: {
          boxShadow: "0 0px 16px 0 rgba(0,0,0,0.12)",
          // boxShadow: "none",
        },
      },
    },

    // ACtive Issue in MUI - https://github.com/mui/mui-x/issues/9311
    // Scrollbar overlaps the text temporary solution is to override width of column
    MuiMultiSectionDigitalClock: {
      styleOverrides: {
        root: {
          "&&": {
            ".MuiList-root": {
              width: "65px",
            },
          },
        },
      },
    },
  },
});

export function themeWithOverrides(overrides: ThemeOptions = {}) {
  const overridesWithDefaults = merge({ palette: basePalette() }, overrides);
  const computedTheme = responsiveFontSizes(
    createTheme(
      merge({
        ...overridesTheme,
        ...overridesWithDefaults,
      })
    )
  );
  // console.log("themeWithOverrides", { overrides, computedTheme });
  return computedTheme;
}

// Export with `responsiveFontSizes` helper to size down fonts on smaller
// screens
export default responsiveFontSizes(overridesTheme);
