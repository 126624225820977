import { WithRef } from "@converge-collective/common/models/Base";
import {
  Network,
  NetworkMembership,
} from "@converge-collective/common/models/Network";
import {
  isNetworkAdmin as _isNetworkAdmin,
  isNetworkAdmin,
} from "@converge-collective/common/models/NetworkAdmin";
import { Profile } from "@converge-collective/common/models/Profile";
import { UserClaims } from "@converge-collective/common/models/User";
import { User } from "firebase/auth";
import { updateDoc } from "firebase/firestore";
import { create } from "zustand";

export type LoggedInState = {
  user?: User;
  setUser: (user?: User) => void;

  profile?: WithRef<Profile>;
  setProfile: (profile?: WithRef<Profile>) => void;
  updateFirestoreProfile: (profile: Partial<Profile>) => void;

  network?: WithRef<Network>;
  setNetwork: (network?: WithRef<Network>) => void;

  userClaims?: UserClaims;
  setUserClaims: (userClaims?: UserClaims) => void;

  isNetworkAdmin: boolean;
  setIsNetworkAdmin: (isNetworkAdmin: boolean) => void;
};

/**
 * Store logged in state in a single store in order to avoid fetching it
 * multiple times in different components.
 *
 * The Auth component sets the state when the user logs in, and clears it when
 * the user logs out.
 */
export const useLoggedInState = create<LoggedInState>()((set, get) => ({
  userClaims: undefined,
  setUserClaims: (userClaims?: UserClaims) => {
    // claims are set, re-compute whether they are a network admin on the
    // current network
    set({ userClaims });

    try {
      const network = get().network;
      if (userClaims && network) {
        console.log("computing isNetworkAdmin in loggedInState", {
          userClaims,
          network,
        });
        const isNA = isNetworkAdmin(userClaims, network.id);
        // console.log("recomputing isNetworkAdmin in loggedInState", {
        //   userClaims,
        //   network,
        //   isNA,
        // });
        set({ isNetworkAdmin: isNA });
      } else {
        console.log(
          "unable to recompute isNetworkAdmin in loggedInState because network or claimsa re missing",
          {
            userClaims,
            network,
          }
        );
      }
    } catch (e) {
      console.log("error setting isNetworkAdmin", e);
    }
  },

  /** default to false, will be updated once claims are checked */
  isNetworkAdmin: false,
  setIsNetworkAdmin: (isNetworkAdmin: boolean) => set({ isNetworkAdmin }),

  user: undefined,
  profile: undefined,
  network: undefined,
  setUser: (user?: User) => set({ user }),
  setProfile: (profile?: WithRef<Profile>) => set({ profile }),
  setNetwork: (network?: WithRef<Network>) => set({ network }),
  /**
   * Provide a convenience profile updater. This makes it easier to keep track
   * of all profile update usages with the type system as opposed to using the
   * raw firestre `updateDoc` throughout the app
   *
   * Unlike the above operations which only interact with memory, this writes
   * to Firestore.
   */
  updateFirestoreProfile: (partialProfile: Partial<Profile>) => {
    const profile = get().profile;
    if (profile) {
      updateDoc(profile.ref, partialProfile);
    }
  },
}));
