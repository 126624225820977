import { formatDateTime } from "@converge-collective/common/util";
import Close from "@mui/icons-material/Close";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { AlertColor } from "@mui/material/Alert";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { createGlobalState } from "react-hooks-global-state";
import { v4 as uuid } from "uuid";

export type GlobalAlert = {
  id: string;
  title?: string;
  message: React.ReactNode;
  action?: React.ReactNode;
  severity?: AlertColor;
  date?: Date;
  closed: boolean;
};

const initState: {
  alerts: Record<string, GlobalAlert>;
} = {
  alerts: {},
};

export const { useGlobalState, getGlobalState, setGlobalState } =
  createGlobalState(initState);

const closeAllAlerts = (): void => {
  const alerts = getGlobalState("alerts");
  const closedAlerts = Object.values(alerts).map((alert) => ({
    ...alert,
    closed: true,
  }));
  setGlobalState(
    "alerts",
    Object.fromEntries(closedAlerts.map((a) => [a.id, a]))
  );
};

const optionsDefaults = {
  autoClose: true,
  autoCloseDelay: 5000,
};

export const createAlert = (
  alert: Omit<GlobalAlert, "id" | "closed"> & { id?: string },
  options: {
    autoClose?: boolean;
    autoHideDuration?: number;
  } = optionsDefaults
): void => {
  const id = alert.id || uuid();
  const message = (
    <Box
      sx={{
        maxWidth: "sm",
        mr: 2,
        mt: "-2px",
        WebkitLineClamp: 4,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        display: "-webkit-box",
        whiteSpace: "pre-wrap",
        "& a": {
          color: "inherit",
          textDecoration: "underline",
        },
      }}
    >
      {alert.title && <Typography variant="h6">{alert.title}</Typography>}
      <Typography variant="body1">{alert.message}</Typography>
      {alert.date && (
        <Typography variant="caption">{formatDateTime(alert.date)}</Typography>
      )}
    </Box>
  );
  console.log("enqueueSnackbar", { id, message });
  enqueueSnackbar(message, {
    key: id,
    variant: alert.severity || "info",
    autoHideDuration:
      options.autoClose === false ? null : options.autoHideDuration || 5000,
    anchorOrigin: { vertical: "bottom", horizontal: "right" },
    // persist: true,
    action: (snackbarId) => (
      <Stack direction="row" alignItems="center" spacing={1}>
        {alert.action}
        <IconButton
          size="small"
          color="inherit"
          onClick={() => closeSnackbar(snackbarId)}
        >
          <Close fontSize="small" />
        </IconButton>
      </Stack>
    ),
  });

  // const opts = { ...optionsDefaults, ...options };
  // console.log("createAlert", { alertWithoutId });
  // // always close existing alerts when opening a new one
  // closeAllAlerts();

  // const id = uuid();
  // const alert: GlobalAlert = { ...alertWithoutId, closed: false, id };
  // // automatically close the alert in 5 seconds
  // if (opts.autoClose) {
  //   setTimeout(() => {
  //     console.log("auto close alert", { alert });
  //     const alertLatest = getGlobalState("alerts")[id];
  //     if (alertLatest.closed) return;
  //     // only update the alert if it's still open to avoid re-render
  //     updateAlert(id, { ...alertLatest, closed: true });
  //   }, opts.autoCloseDelay);
  // }
  // setGlobalState("alerts", { ...getGlobalState("alerts"), [id]: alert });
};

export const updateAlert = (id: string, newAlertValue: GlobalAlert): void => {
  setGlobalState("alerts", {
    ...getGlobalState("alerts"),
    [id]: newAlertValue,
  });

  console.log("updated alert", { id, newAlertValue });
};
