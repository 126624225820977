import { isBrowser } from "browser-or-node";

import { getApps, initializeApp } from "firebase/app";
import { initializeFirestore } from "firebase/firestore";

export const config = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
};

/**
 * We manually initialize Firebase here because we want to be able to use
 * custom Firebase settings. For example, we want to ignore undefined properties
 * when writing to firestore.
 */
export const initFirebase = () => {
  // console.info("init with env", process.env);
  // must be called first
  if (getApps().length < 1) {
    console.info("Initializing Firebase");
    // must set settings before calling any firestore methods / initializing
    const app = initializeApp(config);
    // tried enablePerformance but it didn't work - the profile never loaded.
    // didn't debug futher.
    //
    if (isBrowser) {
      try {
        const newSettings = {
          ignoreUndefinedProperties: true,
        };
        console.log("updating firestore settings", newSettings);
        initializeFirestore(app, newSettings);
      } catch (e) {
        console.warn("unable to set firestore settings", e);
      }
      console.log("success setting firestore settings");
    }
  } else {
    console.info(
      "Skipping Firebase initialization, an app is already initialized",
      getApps().length
    );
  }
};

// Init both Firebase and the admin
export const tryInit = () => {
  try {
    initFirebase();
  } catch (e) {
    console.info(
      "Firebase already initialized or else something went wrong",
      e
    );
  }
};

export const app = () => getApps().length > 0 && getApps()[0];

initFirebase();
