import { FirebaseError } from "firebase/app";

export const errorMessage = (error: FirebaseError): string => {
  switch (error.code) {
    case "auth/invalid-email":
      return "Invalid email address";
    case "auth/email-already-in-use":
      return "Email address already in use";
    case "auth/weak-password":
      return "Password must be at least 6 characters long";
    case "auth/user-not-found":
      return "User not found";
    case "auth/wrong-password":
      return "Incorrect password";
    case "auth/too-many-requests":
      return "Too many requests. Try again later";
    case "auth/network-request-failed":
      return "Network error. Try again later";
    case "auth/invalid-credential":
      return "Invalid credential";
    case "auth/invalid-verification-code":
      return "Invalid verification code";
    case "auth/invalid-verification-id":
      return "Invalid verification id";
    case "auth/invalid-phone-number":
      return "Invalid phone number";
    case "auth/missing-phone-number":
      return "Missing phone number";
    case "auth/missing-verification-code":
      return "Missing verification code";
    case "auth/missing-verification-id":
      return "Missing verification id";
    case "auth/app-deleted":
      return "App deleted";
    case "auth/app-not-authorized":
      return "App not authorized";
    case "auth/argument-error":
      return "Argument error";
    case "auth/invalid-api-key":
      return "Invalid api key";
    case "auth/invalid-user-token":
      return "Invalid user token";
    case "auth/network-request-failed":
      return "Network request failed";
    case "auth/operation-not-allowed":
      return "Operation not allowed";
    case "auth/requires-recent-login":
      return "Requires recent login";
    case "auth/too-many-requests":
      return "Too many requests";
    case "auth/unauthorized-domain":
      return "Unauthorized domain";
    case "auth/user-disabled":
      return "User disabled";
    case "auth/user-token-expired":
      return "User token expired";
    case "auth/web-storage-unsupported":
      return "Web storage unsupported";
    case "auth/account-exists-with-different-credential":
      return "Account exists with different credential";
    case "auth/auth-domain-config-required":
      return "Auth domain config required";
    case "auth/cancelled-popup-request":
      return "Cancelled popup request";
    case "auth/credential-already-in-use":
      return "Credential already in use";
    case "auth/operation-not-supported-in-this-environment":
      return "Operation not supported in this environment";
    case "auth/popup-blocked":
      return "Popup blocked";
    case "auth/popup-closed-by-user":
      return "Popup closed by user";
    case "auth/unauthorized-domain":
      return "Unauthorized domain";
    case "auth/user-cancelled":
      return "User cancelled";
    case "auth/user-not-found":
      return "User not found";
    case "auth/web-storage-unsupported":
      return "Web storage unsupported";
    case "auth/invalid-user-token":
      return "Invalid user token";
    default:
      return "Unknown error";
  }
};
