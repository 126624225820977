import { Groups } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { isEmpty } from "lodash";
import Link from "next/link";
import { MenuItem, SubMenu, menuClasses } from "react-pro-sidebar";
import { useMyGroups } from "~/hooks/useMyGroups";
import { useLoggedInState } from "~/lib/useLoggedInState";
import { groupsRoute } from "~/routes";
import { GroupMenuItem } from "./GroupMenuItem";

export function GroupsNav({ isOpen }) {
  const { network } = useLoggedInState();

  const { groups: myGroups, status, unreadMessages } = useMyGroups();
  console.log("GroupsNav", { myGroups, status, unreadMessages });

  if (!network || !myGroups || isEmpty(myGroups)) {
    return (
      <Link href={groupsRoute(network?.slug || "")} legacyBehavior>
        <MenuItem icon={<Groups />}>Groups</MenuItem>
      </Link>
    );
  }

  return (
    <SubMenu
      defaultOpen
      icon={<Groups />}
      label="Groups"
      rootStyles={{
        ["." + menuClasses.subMenuContent]: {
          inset: "92px auto auto 0 !important",
          ...(!isOpen && {
            overflow: "scroll",
            maxHeight: "700px",
            overflowX: "hidden",
          }),
        },
      }}
    >
      {(status === "loading" || !myGroups) && (
        <MenuItem>
          <CircularProgress size={20} />
        </MenuItem>
      )}

      {status === "error" && !myGroups && (
        <MenuItem>Something Went Wrong.</MenuItem>
      )}

      {status === "success" &&
        myGroups?.length > 0 &&
        myGroups.map((group) => {
          const unreadCount = unreadMessages?.[group.id] ?? 0;
          return (
            <GroupMenuItem
              key={group.id}
              group={group}
              network={network}
              unreadCount={unreadCount}
            />
          );
        })}

      <Link href={groupsRoute(network?.slug || "")} legacyBehavior>
        <MenuItem icon={<Groups color="primary" />}>All Groups</MenuItem>
      </Link>
    </SubMenu>
  );
}
