import { Button, useMediaQuery, useTheme } from "@mui/material";
import { getAnalytics, logEvent } from "firebase/analytics";
import Link from "next/link";
import React from "react";
import { useFirebaseApp } from "reactfire";
import MobileNav from "~/components/MobileNav";
import { marketingNavItemsLeft, marketingNavItems } from "~/routes";

export const MarketingNav = ({
  setSignInVisible,
  setCreateAccountVisible,
  networkSlug,
  showAuthNav,
  showLeftNav,
}: {
  setSignInVisible: (b: boolean) => void;
  setCreateAccountVisible: (b: boolean) => void;
  networkSlug: string;
  showAuthNav: boolean;
  showLeftNav: boolean;
}): React.ReactElement => {
  const accountButtons = showAuthNav && (
    <>
      <Button onClick={() => setCreateAccountVisible(true)}>
        Create account
      </Button>
      <Button
        sx={{ mx: 1 }}
        onClick={() => {
          setSignInVisible(true);
          // const params = {};
          // must be called client side only
          const analytics = getAnalytics(app);
          logEvent(analytics, "sign_in_click");
        }}
      >
        Sign in
      </Button>
    </>
  );
  const app = useFirebaseApp();
  // TODO - pull the slug from the url instead of hardcoding

  const navRight = marketingNavItems(networkSlug);
  const navLeft = showLeftNav ? marketingNavItemsLeft(networkSlug) : [];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return isMobile ? (
    <>
      <MobileNav networkNav={[...navRight, ...navLeft]} />
      <div style={{ marginLeft: "auto" }} />
      {accountButtons}
    </>
  ) : (
    <>
      {navLeft.map(({ url, onClick, label }) =>
        url ? (
          <Link href={url} key={url} legacyBehavior>
            <Button>{label}</Button>
          </Link>
        ) : (
          <Button key={label} onClick={onClick}>
            {label}
          </Button>
        )
      )}
      <div style={{ marginLeft: "auto" }} />
      {accountButtons}
      {/* <Button */}
      {/*   href="https://buy.stripe.com/28oeYc5aRaoBaUobII" */}
      {/*   target="_blank" */}
      {/*   variant="contained" */}
      {/* > */}
      {/*   Fall Comp Registration */}
      {/* </Button> */}
    </>
  );
};
