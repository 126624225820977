/**
 * this is a partial list from:
 * https://firebase.google.com/docs/reference/js/auth#autherrorcodes
 * trimmed down to errors we might actually expect to encounter
 * chatgpt generated the friendly error messages.
 */
export const FirebaseErrors: { [key: string]: string } = {
  "auth/app-not-authorized":
    "This app is not authorized to use Firebase Authentication.",
  "auth/captcha-check-failed": "Captcha verification failed. Please try again.",
  "auth/code-expired":
    "The verification code has expired. Please request a new one.",
  "auth/cors-unsupported": "This browser does not support CORS requests.",
  "auth/credential-already-in-use":
    "This credential is already associated with another account.",
  "auth/custom-token-mismatch":
    "The custom token used does not match the expected audience.",
  "auth/requires-recent-login":
    "Please log in again to proceed with this operation.",
  "auth/dependent-sdk-initialized-before-auth":
    "Another SDK was initialized before Firebase Authentication.",
  "auth/dynamic-link-not-activated":
    "Dynamic links are not activated for this project.",
  "auth/email-change-needs-verification":
    "Your email change needs to be verified before proceeding.",
  "auth/email-already-in-use":
    "The email address is already in use by another account.",
  "auth/emulator-config-failed": "Failed to configure the Firebase emulator.",
  "auth/expired-action-code":
    "The action code has expired. Please request a new one.",
  "auth/cancelled-popup-request": "The popup request was canceled.",
  "auth/internal-error": "An internal error occurred. Please try again later.",
  "auth/unauthorized-domain":
    "This domain is not authorized for this operation.",
  "auth/wrong-password": "The password is incorrect.",
  "auth/invalid-phone-number": "The phone number provided is invalid.",
  "auth/invalid-recipient-email": "The recipient email address is invalid.",
  "auth/invalid-sender": "The sender information is invalid.",
  "auth/invalid-verification-id": "The verification ID provided is invalid.",
  "auth/invalid-app-credential": "Invalid credentials",
  "auth/invalid-tenant-id": "The tenant ID provided is invalid.",
  "auth/multi-factor-info-not-found":
    "The multi-factor information was not found.",
  "auth/multi-factor-auth-required":
    "Multi-factor authentication is required for this operation.",
  "auth/missing-android-pkg-name": "The Android package name is missing.",
  "auth/missing-app-credential": "The app credential is missing.",
  "auth/auth-domain-config-required":
    "The authentication domain configuration is required.",
  "auth/missing-verification-code": "The verification code is missing.",
  "auth/missing-phone-number": "The phone number is missing.",
  "auth/missing-verification-id": "The verification ID is missing.",
  "auth/app-deleted": "The app has been deleted.",
  "auth/account-exists-with-different-credential":
    "An account already exists with a different credential.",
  "auth/network-request-failed":
    "A network request has failed. Please check your connection.",
  "auth/null-user": "No user is currently signed in.",
  "auth/no-auth-event": "No authentication event occurred.",
  "auth/popup-blocked": "The popup was blocked by the browser.",
  "auth/popup-closed-by-user":
    "The popup was closed by the user before completion.",
  "auth/provider-already-linked":
    "This provider is already linked to the user.",
  "auth/quota-exceeded": "The quota for this operation has been exceeded.",
  "auth/second-factor-already-in-use": "This second factor is already in use.",
  "auth/maximum-second-factor-count-exceeded":
    "The maximum number of second factors has been exceeded.",
  "auth/tenant-id-mismatch": "The tenant ID does not match.",
  "auth/timeout": "The operation timed out.",
  "auth/user-token-expired": "The user's authentication token has expired.",
  "auth/too-many-requests": "Too many attempts. Please try again later.",
  "auth/unsupported-persistence-type": "This persistence type is unsupported.",
  "auth/unsupported-tenant-operation": "This tenant operation is unsupported.",
  "auth/unverified-email": "The user's email is unverified.",
  "auth/user-cancelled": "The user cancelled the operation.",
  "auth/user-not-found": "We couldn't find a user with that email.",
  "auth/user-disabled": "The user has been disabled.",
  "auth/user-mismatch": "The user does not match the provided credentials.",
  "auth/user-signed-out": "The user has signed out.",
  "auth/weak-password": "The password is too weak.",
  "auth/already-initialized": "The app has already been initialized.",
  "auth/recaptcha-not-enabled": "reCAPTCHA is not enabled for this project.",
  "auth/missing-recaptcha-token": "The reCAPTCHA token is missing.",
  "auth/invalid-recaptcha-token": "The reCAPTCHA token is invalid.",
  "auth/invalid-recaptcha-action": "The reCAPTCHA action is invalid.",
  "auth/missing-client-type": "The client type is missing.",
  "auth/missing-recaptcha-version": "The reCAPTCHA version is missing.",
  "auth/invalid-recaptcha-version": "The reCAPTCHA version is invalid.",
  "auth/invalid-req-type": "The request type is invalid.",
};
