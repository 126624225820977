import { converters } from "~/lib/converter";
import { WithBase, WithRef } from "@converge-collective/common/models/Base";
import { Challenge } from "@converge-collective/common/models/Challenge";
import { NetworkMembership } from "@converge-collective/common/models/Network";
import {
  Profile,
  ProfilePreferences,
  ProfilePrivateAttributes,
} from "@converge-collective/common/models/Profile";
import { converter } from "@converge-collective/common/util";
import {
  collectionGroup,
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { useMemo, useEffect, useState } from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { ActivityLogKinds } from "@converge-collective/common/models/ActivityLog";
import { useLoggedInState } from "~/lib/useLoggedInState";

// TODO - consider hook creators that accept:
// 1. params object
// 2. path in firestore (template string that can access params)
// and returns [state, setState] array similar to useState.
// it's basically useState where firestore is the state store.

export default function useProfile(
  userId?: string
): [
  WithRef<Profile> | null,
  ((p: Partial<Profile>, merge?: boolean) => Promise<void>) | undefined,
  isLoading: boolean,
] {
  const [isLoading, setIsLoading] = useState(true);
  const [profile, setProfile] = useState<WithRef<Profile> | null>(null);
  const firestore = useFirestore();
  // console.log("useProfile", { userId });
  // listen to updates
  useEffect(() => {
    if (userId) {
      try {
        const profileRef = doc(firestore, "profiles", userId).withConverter(
          converter<Profile>()
        );
        // onSnapshot can return partial results, so we need to populate the
        // profile the first time
        const unsub = onSnapshot(profileRef, (p) => {
          // when profile is retrieved from cache it'll contain pending writes.
          // when the user first loads the app we update `timestamp`, so the
          // incomplete profile will only include `timestamp` and `id`, unless we
          // ignore it when fromCache is true.
          // so: avoid setting the profile until it's complete

          if (!p.metadata.fromCache && p.exists()) {
            const profile = p.data();
            setProfile(profile);
          }
        });
        return () => unsub();
      } catch (e) {
        console.error("error loading profile", { e, userId });
      } finally {
        setIsLoading(false);
      }
    }
  }, [firestore, userId]);

  const updateProfile = async (p: Partial<Profile>, merge = true) => {
    console.log("setProfile", { profile: p, merge });
    userId && (await setDoc(doc(firestore, "profiles", userId), p, { merge }));
  };

  return [profile, userId ? updateProfile : undefined, isLoading];
}

/** previously we tried to store the fully hydrated network member but now we
 * only store the ref, so use it to lookup the full NM */
export function useHydratedCurrentNetworkMembership() {
  // we used to look up profile.currentNetworkMembership but this field should
  // never be used to look up active network memberships because a user may have
  // many tabs open with different networks. instead always fetch it from the
  // active network.
  const { network, profile } = useLoggedInState();
  const { data: networkMemberships = [] } = useNetworksMemberships(profile?.id);
  const currentNm = networkMemberships.find(
    (nm) => nm.network.id === network?.id
  );
  // console.log("useHydratedCurrentNetworkMembership", {
  //   profile,
  //   currentNm,
  //   networkMemberships,
  //   currentNetworkId,
  // });
  return currentNm;
}

// TODO consider a fn that can generate hooks like these
export function usePrivateAttrs(
  userId?: string
): [
  WithBase<ProfilePrivateAttributes> | null,
  (p: ProfilePrivateAttributes) => Promise<void>,
] {
  const [data, setData] = useState<WithBase<ProfilePrivateAttributes> | null>(
    null
  );
  const firestore = useFirestore();
  console.log("useProfile", { userId });
  // listen to updates
  useEffect(() => {
    if (userId) {
      const profileRef = doc(
        firestore,
        "profiles",
        userId,
        "private",
        "attributes"
      ).withConverter(converter<ProfilePrivateAttributes>());
      const unsub = onSnapshot(profileRef, (p) => {
        const prefs = {
          ...p.data(),
        } as WithBase<ProfilePrivateAttributes>;
        setData(prefs);
      });
      return () => unsub();
    }
  }, [firestore, userId]);
  return [
    data,
    async (p: ProfilePrivateAttributes) => {
      userId &&
        (await setDoc(
          doc(firestore, "profiles", userId, "private", "attributes"),
          p,
          { merge: true }
        )); // use set incase the doc doesn't exist yet
    },
  ];
}

// return network memberships that the user is a member of
export function useNetworksMemberships(userId?: string) {
  const firestore = useFirestore();
  // const [data, setData] = useState<NetworkMembership>();
  const profileRef = useMemo(
    () =>
      doc(firestore, "profiles", userId || "noop").withConverter(
        converters.profile.read
      ),
    [userId, firestore]
  );
  // consider storing a copy of every NM on the user's profile instead of
  // running a collection group query across all networks :thinking:

  const nmQuery = useMemo(
    () =>
      query(
        collectionGroup(firestore, "members").withConverter(
          converters.networkMembership.read
        ),
        where("profileRef", "==", profileRef)
      ),
    [profileRef, firestore]
  );

  // const data = [];
  return useFirestoreCollectionData(nmQuery);
}

export function usePreferences(
  userId?: string
): [
  ProfilePreferences | null,
  (p: ProfilePreferences) => Promise<void>,
  boolean,
] {
  const [data, setData] = useState<ProfilePreferences | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const firestore = useFirestore();
  console.log("useProfile", { userId });
  // listen to updates
  useEffect(() => {
    if (userId) {
      const profileRef = doc(
        firestore,
        "profiles",
        userId,
        "private",
        "preferences"
      ).withConverter(converter<ProfilePreferences>());
      const unsub = onSnapshot(profileRef, (p) => {
        const prefs = { ...p.data(), id: p.id } as ProfilePreferences;
        setData(prefs);
        setIsLoaded(true);
      });
      return () => unsub();
    }
  }, [firestore, userId]);
  return [
    data,
    async (p: ProfilePreferences) => {
      userId &&
        (await setDoc(
          doc(firestore, "profiles", userId, "private", "preferences"),
          p,
          {
            merge: true,
          }
        ));
    },
    isLoaded,
  ];
}

export function useCurrentChallenges(userId?: string): Challenge[] | null {
  const [challenges, setChallenges] = useState<Challenge[] | null>(null);
  const [profile] = useProfile(userId);
  const firestore = useFirestore();

  useEffect(() => {
    const challengesJoined = profile?.challengesJoined;
    const fetchChallenges = async () => {
      console.log("fetch challenges");
      const challengeData = (
        await Promise.all(
          (challengesJoined as string[]).map(async (challengePath: string) => {
            const challengeRef = doc(firestore, challengePath).withConverter(
              converter<Challenge>()
            );
            const challenge = await getDoc(challengeRef);
            return challenge.data();
          })
        )
      )
        // filter out challenges that only have an ID and ref - this happens when a
        // challenge that the user previously joined was deleted
        .filter(
          (challenge) => challenge && Object.keys(challenge).length > 2
        ) as Challenge[];
      setChallenges(challengeData);
    };
    if (challengesJoined) fetchChallenges();
  }, [firestore, profile]);

  return challenges;
}

type ActivityConfig = {
  kind: keyof typeof ActivityLogKinds;
  id: string;
};
export function useUserActivityLogs(userId?: string, config?: ActivityConfig) {
  const firestore = useFirestore();

  // Ref to collection
  const logsCol = userId
    ? collection(firestore, "profiles", userId, "activityLogs")
    : collection(firestore, "noop");

  // Where Condition
  const logsQueryWhere =
    config && config.kind === ActivityLogKinds.GoalActivityLog
      ? where("goalChallengeId", "==", config?.id)
      : null;

  // Logs Query
  const userActivityLogsRef = (
    logsQueryWhere ? query(logsCol, logsQueryWhere) : query(logsCol)
  ).withConverter(converters.activityLog.read);

  // Logs Data
  const { data } = useFirestoreCollectionData(userActivityLogsRef);

  return { logs: !userId ? [] : data, ref: logsCol };
}
