import { WithRef } from "@converge-collective/common/models/Base";
import {
  Network,
  NetworkMembership,
} from "@converge-collective/common/models/Network";
import { Profile } from "@converge-collective/common/models/Profile";
import { modelFromSnap } from "@converge-collective/common/util";
import {
  collectionGroup,
  doc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useFirestore, useFirestoreDoc, useUser } from "reactfire";
import { converters } from "~/lib/converter";

export function useNetworkMembershipFromUrl():
  | WithRef<NetworkMembership>
  | undefined {
  const [networkMembership, setNetworkMembership] =
    useState<WithRef<NetworkMembership>>();

  const router = useRouter();
  const firestore = useFirestore();
  const { data: user } = useUser();
  const networkToFetch = router?.query?.network;

  useEffect(() => {
    const fetchNetworkMembership = async () => {
      console.log("fetchNetworkMembership", { user, router, networkToFetch });
      if (user) {
        const profileRef = doc(firestore, `profiles/${user.uid}`);
        const q = query(
          collectionGroup(firestore, "members"),
          // filtering to networks that the current user is a member of
          where("profileRef", "==", profileRef),
          where("network.slug", "==", networkToFetch)
        );
        const results = await getDocs(q);
        if (!results.empty) {
          const [firstDoc] = results.docs;
          const nm = modelFromSnap<NetworkMembership>(firstDoc);
          setNetworkMembership(nm);
        }
      }
    };
    networkToFetch && fetchNetworkMembership();
  }, [user, router, networkToFetch, firestore]);

  return networkMembership;
}

export function useNetworkMembership({
  network,
  profile,
}: {
  network?: WithRef<Network>;
  profile?: WithRef<Profile>;
}): {
  networkMembership: WithRef<NetworkMembership> | undefined;
  isLoading: boolean;
} {
  const firestore = useFirestore();
  const { data, status } = useFirestoreDoc(
    (network && profile
      ? doc(network.ref, "members", profile.id)
      : doc(firestore, "noop/noop")
    ).withConverter(converters.networkMembership.read)
  );
  const networkMembership = data?.data();
  const isLoading = status === "loading";
  return { isLoading, networkMembership };
}
