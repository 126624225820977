import { isBrowser } from "browser-or-node";
import { Cache } from "swr";

const localStorageKey = "converge-swr-app-cache";

export function localStorageProvider(): Cache<unknown> {
  // When initializing, we restore the data from `localStorage` into a map.
  const map = new Map(
    JSON.parse((isBrowser && localStorage.getItem(localStorageKey)) || "[]")
  );

  // Before unloading the app, we write back all the data into `localStorage`.
  isBrowser &&
    window.addEventListener("beforeunload", () => {
      const appCache = JSON.stringify(Array.from(map.entries()));
      localStorage.setItem(localStorageKey, appCache);
    });

  // We still use the map for write & read for performance.
  return map as Cache<unknown>;
}
