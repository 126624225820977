import { Button } from "@mui/material";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useRouter } from "next/router";
import { useFirebaseApp } from "reactfire";

export const LoggedOutNav = ({
  setSignInVisible,
  setCreateAccountVisible,
}: {
  setSignInVisible: (b: boolean) => void;
  setCreateAccountVisible: (b: boolean) => void;
}): React.ReactElement => {
  const app = useFirebaseApp();
  const router = useRouter();
  const isBillingsPage = router.asPath.startsWith("/billings");
  return (
    <>
      <div style={{ marginLeft: "auto" }} />
      {/* We only show Create Account on the Billings network.
        on every other page we don't show it because Billings Area is the only
        public network */}
      {isBillingsPage && (
        <Button onClick={() => setCreateAccountVisible(true)}>
          Create account
        </Button>
      )}
      <Button
        sx={{ ml: 1 }}
        onClick={() => {
          setSignInVisible(true);
          // const params = {};
          // must be called client side only
          const analytics = getAnalytics(app);
          logEvent(analytics, "sign_in_click");
        }}
      >
        Sign in
      </Button>
    </>
  );
};
